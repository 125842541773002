import React from 'react';
import '../../App.css';
import CareersCardList from '../CareerCardList';
import Footer from '../Footer';

function Careers() {

  const careers = [
    {
      title: 'Transport Supervisor',
      location:'Manila',
      description: 'Overall, he/she is responsible for operations planning and execution, SOP compliance and continuous improvement of transport operations. Also, responsible for communicating with the sales team or other functions any impact arising from transport availability issues and other operational concerns.',
      requirements: ['Bachelor\'s degree in Computer Science or equivalent', 'Experience with JavaScript, React, and Node.js']
    },
    {
      title: 'Warehouse Team Leader',
      location:'Manila',
      description: 'Overall responsible for the warehouse operation of a section within the Warehouse function. Supporting the Management Team with the day-to-day operation of the warehouse, responsible for a group of operators and ensuring an efficient warehouse operation in achieving business targets.',
      requirements: ['Bachelor\'s degree in Marketing or related field', 'Experience with digital marketing platforms']
    },
    {
      title: 'Accounting Manager',
      location:'Cebu City',
      description: 'We are looking for a knowledgeable Accounting Manager who can lead and motivate accounting staff members and increase department accuracy and efficiency. The Accounting Manager will delegate, supervise, and participate in accounting department tasks, oversee the recording, tracking, and reporting of financial information, hire, train, and motivate accounting staff members, develop and implement department controls and procedures, and ensure that the department operates efficiently without sacrificing accuracy.',
      requirements: ['Bachelor\'s degree in Marketing or related field', 'Experience with digital marketing platforms']
    },
    {
      title: 'Customs Processor',
      location:'Cebu City',
      description: 'A Customs Processor is responsible for the preparation of Import Air & Sea shipping documents and approval of Entry Form at PEZA (and outside of PEZA) and Bureau of Customs offices. The job includes the releasing of cargo from the shipping line’s bonded warehouse and transfers to delivery truck. He/She has to ensure that the forms are filled out completely and accurately without delays and copies are completely distributed to concerned parties.',
      requirements: ['Bachelor\'s degree in Marketing or related field', 'Experience with digital marketing platforms']
    },
    {
      title: 'Accounts Receivable Assistant',
      location:'Cebu City',
      description: 'An accounts receivable assistant prepares accounts receivable transactions and processes customer payments. They also create invoices according to company practices and submit them for approval by management or clients.',
      requirements: ['Bachelor\'s degree in Marketing or related field', 'Experience with digital marketing platforms']
    },
    {
      title: 'Customer Service & Sales Support',
      location:'Cebu City',
      description: 'The Customer Service/Sales Support is responsible for Assist Customer Experience Representatives in managing their day-today activities and operations. Maintain and further develop quality relationships with existing customers and warehouses. Schedule or reschedule pickup and delivery appointments.',
      requirements: ['Bachelor\'s degree in Marketing or related field', 'Experience with digital marketing platforms']
    },
    {
      title: 'HR & Admin Assistant',
      location:'Manila',
      description: 'The position primarily supports human resources processes by administering tests, scheduling appointments, conducting orientation, maintaining records and information. It also performs administrative functions related to operations.',
      requirements: ['Bachelor\'s degree in Marketing or related field', 'Experience with digital marketing platforms']
    },
    {
      title: 'Accounting Assistant',
      location:'Manila',
      description: 'We are searching for a motivated Accounting Assistant who is an excellent multitasker with exceptional communication and time management skills. Accounting Assistants can expect to assist the Accounting department with data entry, processing, and recording transactions, updating the ledger, assisting with audits or fact-checking, and preparing budgets and reports. You should be thorough, accurate, and honest with good bookkeeping skills.',
      requirements: ['Bachelor\'s degree in Marketing or related field', 'Experience with digital marketing platforms']
    },
  ];
  
  return(
    <>
    <div className="contactUs-container">
     <div className="contactUs-img-container">
      <div className="contactUs-content">
        <p><span style={{color:'red'}}>C</span>areers</p>
      </div>
    </div>
    </div>
   <div className='card'>
    <CareersCardList careers={careers}/>
   </div>
   <Footer />
   </>
  )
}
export default Careers;