import React from 'react';
import '../App.css';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
      <div className='text-container'>
        <h1><span style={{color:'rgb(255, 225, 0)'}}>Passion Beyond Service,</span> <span style={{color:'rgb(255, 225, 0)'}}>Solutions Unlimited</span> </h1>
        <hr></hr>
      </div>
      <div className="image-container">
        <img src="images/customs.png" alt='Trucks Background' />
      </div>
      <div className="image-container">
        <img src="images/cargo.png" alt='Air-Freight Background' />
      </div>
      <div className="image-container">
        <img src="images/warehouse.png" alt='Sea-Freight Background' />
      </div>
      <div className="image-container">
        <img src="images/customized-ops.png" alt='Warehouse' />
      </div>
      
    </div>
  );
}

export default HeroSection;
