import React, { useState } from 'react';
import './CareerCard.css'; // Import CSS file for styling
const CareerCard = ({ title, description, requirements, location }) => {
  const [applyMsg, setApplyMsg] = useState('none');
  const [jo, setJo] = useState('');
  
  const applyBtn = (description) => {
    setApplyMsg('block')
    setJo(description);
    document.body.classList.add('modal-open');
  }

  const closeApplyBtn = () => {
    document.body.classList.remove('modal-open');
    setApplyMsg('none');
  }

    return (
      <>
      <div className="dialog-container-careers" style={{display: applyMsg}}>
            <div className="dialog-content">
                <div className="contact-info">
                  <div>
                    <h3>Job Overview:</h3>
                    <hr className='career-card-hr'></hr>
                    <p>{jo}</p>
                  </div>
                </div>
                <div className="qr-code">
                    {/* <QRCode className="qr" value="https://www.facebook.com/l98brokerage" /> */}
                    <h3>Join Our Team: Send Us Your Resume Today!</h3>
                    <p>To apply, please send your resume to <span style={{fontWeight:'bold', textDecoration:'underline', textDecorationColor:'red'}}>hr@l98.com.ph </span> 
                      with the subject line "Application for <span style={{fontWeight:'bold'}}>{title}</span> Position". 
                      Feel free to include a brief cover letter highlighting why 
                      you're interested in joining our team and how your skills and experiences align with our mission.</p>
                    <p>We can't wait to hear from you.</p>
                </div>
            </div>
            <button className='career-card-close-btn' onClick={closeApplyBtn}>Close</button>
        </div>
      <div className="career-card">
        <div className='career-card-title'> 
        <h3>{title}</h3>
        <h5>{location}</h5>
        </div>
        <hr className='career-card-hr'></hr>
        <div className='career-card-description'>
        <p >{description}</p>
        </div>
        {/* <ul>
          {requirements.map((requirement, index) => (
            <li key={index}>{requirement}</li>
          ))}
        </ul> */}
        <button className='career-card-btn' onClick={()=> applyBtn(description)}>Apply</button>
      </div>
      </>
    );
  };

export default CareerCard;