import React from 'react';
import '../../components/AboutUs.css';
import CoreValues from '../CoreValues';
import Footer from '../Footer';

function AboutUs() {
  const url = window.location.pathname;
  return (
    <>
    {url === '/about-us' ? <div className="contactUs-container">
     <div className="contactUs-img-container">
      <div className="contactUs-content">
        <p><span style={{color:'red'}}>A</span>bout Us</p>
      </div>
    </div>
    </div> : ''
    }
   <div className='card'>
    {/* <h1>About Us</h1> */}
      <img src='images/L98-Logo.png' alt='L98 Logo'/>
      <p id='about-us-text'><span style={{color:'red'}}>L</span><span style={{color:'black', fontWeight:'bolder'}}>98</span> <span style={{color:'gray'}}>Brokerage & Logistics, Inc.</span> is a specialized customs brokerage and logistics services provider.
          Incorporated on February 2012 with five employees. Eleven years later of operations, our manpower has grown to more than 115 employees,
          with epertise and proven leadership in the field of documentation & logistics. Headquartered in a strategic location of Mandaue, we are 5 minutes from airport
          and 10 minutes from the seaport. Our satellite offices installed inside PEZA is inteded to have
          a faster transaction to our customers. A similar satellite office is installed in a Special Economic zone, Westside of Cebu.
          With these satellite offices, this brings added value of our customers for faster transactions with PEZA and Customs offices. In Manila Luzon,
          our office is strategically located adjacent to Ninoy Aquino International Airport while in Mindanao, is located central district of Davao City.
      </p>
      <h2>Our Milestones</h2>
      <hr></hr>
      <div className='timeline'>
        <div className='container left-container'>
          <img src="images/l98.png" alt='L98-Logo-1'/>
          <div className='text-box'>
            <h3><span style={{color:'red'}}>L</span><span style={{color:'black', fontWeight:'bolder'}}>98</span><span style={{color:'gray'}}> Brokerage & Logistics, Inc.</span></h3>
            <small>Year 2012</small>
            <p>Incorporated on February 2012 with the remarkable growth from a small team of 5 employees to now over <span style={{color:'red'}}>200</span> dedicated individuals.</p>               
            <span className='left-container-arrow'></span>
          </div>
        </div>
        <div className='container right-container'>
        <img src="images/l98.png" alt='L98-Logo-2'/>
          <div className='text-box'>
          <h3><span style={{color:'red'}}>F</span><span style={{color:'black', fontWeight:'bolder'}}>irst</span><span style={{color:'gray'}}> Transport Unit</span></h3>
            <small>Year 2013</small>
            <p>The remarkable growth of our fleet from its humble beginnings with our first truck units to now boasting over <span style={{color:'red'}}>10 trucks & 54 chassis</span> units.</p>
              
              
            <span className='right-container-arrow'></span>
          </div>
        </div>
        <div className='container left-container'>
        <img src="images/l98.png" alt='L98-Logo-3'/>
          <div className='text-box'>
          <h3><span style={{color:'red'}}>F</span><span style={{color:'black', fontWeight:'bolder'}}>irst</span><span style={{color:'gray'}}> satellite</span></h3>
            <small>Year 2015</small>
            <p>The expansion of our satellite network from the successful launch of our first satellite to now operating <span style={{color:'red'}}>5</span> satellites across Philippines</p>
            <span className='left-container-arrow'></span>
          </div>
        </div>
        <div className='container right-container'>
        <img src="images/l98.png" alt='L98-Logo-4'/>
          <div className='text-box'>
          <h3><span style={{color:'red'}}>D</span><span style={{color:'black', fontWeight:'bolder'}}>avao</span><span style={{color:'gray'}}> Branch</span></h3>
            <small>Year 2019</small>
            <p>The successful opening of our first branch in <span style={{color:'red'}}>Mindanao</span> marking a pivotal moment in our expansion and growth.</p>
            <span className='right-container-arrow'></span>
          </div>
        </div>
        <div className='container left-container'>
          <img src="images/l98.png" alt='L98-Logo-5'/>
          <div className='text-box'>
          <h3><span style={{color:'red'}}>M</span><span style={{color:'black', fontWeight:'bolder'}}>anila</span><span style={{color:'gray'}}> Branch</span></h3>
            <small>Year 2023</small>
            <p>The grand opening of our second branch in <span style={{color:'red'}}>Manila</span>. This milestone marks a significant step forward in our expansion efforts
               and underscores our commitment to serving our customers and communities in new and exciting ways.</p>
            <span className='left-container-arrow'></span>
          </div>
        </div>
      </div>

      <div className="mission-vision-container">
      <div className="mission-card">
        <h2>Mission</h2>
        <hr></hr>
          <div className='mission-text'>To provide excellent service that will delight our customers and will be considered as their primary service provider</div>
          <div className='mission-text'>To Deliver the best solution for logistical need of our customers</div>
          <div className='mission-text'>To Ensure sustainability of company's revenue with continous financial growth</div>
          <div className='mission-text'>To Ensure employee's welfare and to support their professional growth</div>
      </div>
      <div className="vision-card">
        <h2>Vision</h2>
        <hr></hr>
        <div className='mission-text'>To be the best customs and documentation specialist in the Philippines, with a trademark of world-class service</div>
        <div className='mission-text'>To be the one-stop solutions provider for all logistical needs with door to door service</div>
        <div className='mission-text'>To be technological-driven logistics service provider in the Philippines</div>
      </div>
    </div>
    <h2>Core Values</h2>
    <hr></hr>
    <CoreValues />

   </div>
    {url === '/about-us' ? <Footer /> : ''}
   </>
  )
}
export default AboutUs;