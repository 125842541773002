import React, { useState } from 'react';
import '../Services.css';
import { BsArrowDownUp, BsBox, BsBuildingLock, BsTruck, BsAirplane, BsCartCheck } from "react-icons/bs";
import QRCode from 'qrcode.react';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
function Services() {
  const [isActiveCargo, setIsActiveCargo] = useState('none');
  const [isActiveAirFrieght, setIsActiveAirFrieght] = useState('block');
  const [isActiveTrucking, setIsActiveTrucking] = useState('none');
  const [isActiveWarehouse, setIsActiveWarehouse] = useState('none');
  const [isActiveCustomized, setIsActiveCustomized] = useState('none');
  const [isecommerce, setIsEcommerce] = useState('none');
  const [inquireMsg, setInquireMsg] = useState('none')
  let width = window.innerWidth;
const handleServices = (service) => {
  setInquireMsg('none')
  if(service === 'af') {
    setIsActiveAirFrieght('block');
    setIsActiveCargo('none');
    setIsActiveTrucking('none');
    setIsActiveWarehouse('none');
    setIsActiveCustomized('none');
    setIsEcommerce('none');
  } else if (service === 'cargoForwarding') {
    setIsActiveCargo('block');
    setIsActiveAirFrieght('none');
    setIsActiveTrucking('none');
    setIsActiveWarehouse('none');
    setIsActiveCustomized('none');
    setIsEcommerce('none');
  } else if (service === 'trucking') {
    setIsActiveTrucking('block');
    setIsActiveAirFrieght('none');
    setIsActiveCargo('none');
    setIsActiveWarehouse('none');
    setIsActiveCustomized('none');
    setIsEcommerce('none');
  } else if (service === 'warehouse') {
    setIsActiveWarehouse('block');
    setIsActiveAirFrieght('none');
    setIsActiveCargo('none');
    setIsActiveTrucking('none');
    setIsActiveCustomized('none');
    setIsEcommerce('none');
  } else if (service === 'customized') {
    setIsActiveCustomized('block');
    setIsActiveAirFrieght('none');
    setIsActiveCargo('none');
    setIsActiveWarehouse('none');
    setIsActiveTrucking('none');
    setIsEcommerce('none');
  } else if (service === 'ecommerce') {
    setIsEcommerce('block');
    setIsActiveCustomized('none');
    setIsActiveAirFrieght('none');
    setIsActiveCargo('none');
    setIsActiveWarehouse('none');
    setIsActiveTrucking('none');
  }
  

}

const inquireBtn = () => {
  setInquireMsg('block')
  document.body.classList.add('modal-open');
}

const closeApplyBtn = () => {
  document.body.classList.remove('modal-open');
  setInquireMsg('none');
}

  return (
    <>
    <div className="carousel-container">
      <div className="dialog-container" style={{display: inquireMsg}}>
            <div className="dialog-content">
                <div className="contact-info">
                  <div>
                    <div className='dialog-header'> 
                    <h3>Cebu and Davao Contact Information</h3>
                    {width > 768 ? '' : <Link
                    className='social-icon-link facebook'
                    to='https://www.facebook.com/l98brokerage'
                    target='_blank'
                    aria-label='Facebook'
                    >
                      <i className='fab fa-facebook-f' />
                    </Link>}
                    </div>
                    <hr></hr>
                    
                    <p>Contact Number: <a href="tel:322344869"> +63 234-4869 </a></p>
                    <p>Email Address: L98admin@L98.com.ph</p>
                    <p>Address: Zone 5J Bridges Townsquare Plaridel St. Alang-Alang Mandaue City, Cebu, Philippines</p>
                    </div>
                    <div>
                    <h3>Metro Manila Contact Information</h3>
                    <hr></hr>
                    <p>Contact Number:  <a href="tel:0286919469">+63 8691-9469</a></p>
                    <p>Email Address: L98admin@L98.com.ph</p>
                    <p>Address: #8 Olivarez Plaza, NAIA Avenue, Brgy. San Dionisio, Paranaque City, Philippines</p>
                    </div>
                </div>
                <div className="qr-code">
                    { width > 768 ? <QRCode className="qr" value="https://www.facebook.com/l98brokerage" /> :   ''}
                </div>
                
            </div>
            <button className='career-card-close-btn' onClick={closeApplyBtn}>Close</button>
        </div>


      <div className='list' style={{display:isActiveAirFrieght}}>
        <div className='item'>
          <img src='images/customs.png' alt=''></img>
          <div className='content'>
            <div className='title'><span style={{color:'red'}}>C</span><span style={{color:'whitesmoke'}}>ustoms Clearance</span></div>
            <div className='description'>We provide expertise in customs regulations, documentation, and clearance processes, helping clients ensure timely and cost-effective clearance of their shipments.
            </div>
            <div className='button'>
              <button onClick={inquireBtn}>Get Quotation</button>
            </div>
          </div>
        </div>
      </div>

      <div className='list'  style={{display:isActiveCargo}}>
        <div className='item'>
          <img src='images/cargo.png' alt=''></img>
          <div className='content'>
            <div className='title'><span style={{color:'red'}}>F</span><span style={{color:'whitesmoke'}}>reight Forwarding</span></div>
            <div className='description'>
            We offer comprehensive logistics solutions for the smooth and efficient transfer of goods across borders and continents.
              <li>Air Freight</li>
              <li>Sea Freight</li>
            </div>
            <div className='button'>
              <button onClick={inquireBtn}>Get Quotation</button>
            </div>
          </div>
        </div>
      </div>

      <div className='list'  style={{display:isActiveTrucking}}>
        <div className='item'>
          <img src='images/trucking-services.png' alt=''></img>
          <div className='content'>
            <div className='title'><span style={{color:'red'}}>T</span><span style={{color:'whitesmoke'}}>ransport Solutions</span></div>
            <div className='description'>
              We provide a wide range of transportation, logistics, and supply chain solutions 
              designed to meet the diverse needs of shippers and consignees. We optimize supply chain performance, 
              and achieve competitive advantage in overall landscape of logistics industry.
              <li>Multimodal</li>
              <li>B2B</li>
              <li>B2C</li>
            </div>
            <div className='button'>
              <button onClick={inquireBtn}>Get Quotation</button>
            </div>
          </div>
        </div>
      </div>

      <div className='list'  style={{display:isActiveWarehouse}}>
        <div className='item'>
          <img src='images/warehouse.png' alt=''></img>
          <div className='content'>
            <div className='title'><span style={{color:'red'}}>W</span><span style={{color:'whitesmoke'}}>arehousing</span></div>
            <div className='description'>
            We offer a centralized location for storing, managing, and distributing items, allowing businesses to optimize inventory levels, minimize expenses, and improve customer service.
              <li>PEZA</li>
              <li>NON PEZA</li>
            </div>
            <div className='button'>
              <button onClick={inquireBtn}>Get Quotation</button>
            </div>
          </div>
        </div>
      </div>

      <div className='list'  style={{display:isActiveCustomized}}>
        <div className='item'>
          <img src='images/customized-ops.png' alt=''></img>
          <div className='content'>
            <div className='title'><span style={{color:'red'}}>C</span><span style={{color:'whitesmoke'}}>ustomized Operations</span></div>
            <div className='description'>
              We offer customized Operations such as:
              <li>Warehouse Manage System (WMS)</li>
              <li>System Development (Automation)</li>
              <li>Project Cargo</li>
              <li>Out Gauge Shipment</li>
              <li>Value Added Services</li>
              <li>Pick and Pack</li>
              <li>Relabeling</li>
              <li>Vanning</li>
              <li>Devanning</li>
            </div>
            <div className='button'>
              <button onClick={inquireBtn}>Get Quotation</button>
            </div>
          </div>
        </div>
      </div>

      <div className='list'  style={{display:isecommerce}}>
        <div className='item'>
          <img src='images/warehouse.png' alt=''></img>
          <div className='content'>
            <div className='title'><span style={{color:'red'}}>E</span><span style={{color:'whitesmoke'}}>-commerce</span></div>
            <div className='description'>
            We offer convenience, accessibility, and flexibility to both sellers and consumers. 
            We are driven by advancements in technology we ensure the express movement of your orders.
              <li>Order Management</li>
              <li>Fulfillment</li>
              <li>First Mile</li>
              <li>Middle Mile</li>
              <li>Last Mile</li>
            </div>
            <div className='button'>
              <button onClick={inquireBtn}>Get Quotation</button>
            </div>
          </div>
        </div>
      </div>


      <div className='thumbnail'>
        <div className={'item ' + isActiveAirFrieght} onClick={() => handleServices('af')}>
          {/* <img src='images/customs.jpg' alt='' ></img> */}
          <BsArrowDownUp className='icon' size={'50px'}/>
          <p>Customs Clearance</p>
        </div>
        <div className={'item ' + isActiveCargo} onClick={() => handleServices('cargoForwarding')}>
          {/* <img src='images/cargo.jpg' alt=''></img> */}
          <BsAirplane className='icon' size={'50px'}/>
          <p>Freight Forwarding</p>
        </div>
        <div className={'item ' + isActiveTrucking} onClick={() => handleServices('trucking')}>
          {/* <img src='images/trucking-services.png' alt='' onClick={() => handleServices('trucking')}></img> */}
          <BsTruck className='icon' size={'50px'}/>
          <p>Transport Solutions</p>
        </div>
        <div className={'item ' + isActiveWarehouse}  onClick={() => handleServices('warehouse')}>
          {/* <img src='images/warehouse.jpg' alt=''></img> */}
          <BsBuildingLock className='icon' size={'50px'}/>
          <p>Warehousing</p>
        </div>
        <div className={'item ' + isActiveCustomized}  onClick={() => handleServices('customized')}>
          {/* <img src='images/customized-services.png' alt='' onClick={() => handleServices('customized')}></img> */}
          <BsBox className='icon' size={'50px'}/>
          <p>Customized Operations</p>
        </div>
        <div className={'item ' + isecommerce}  onClick={() => handleServices('ecommerce')}>
          {/* <img src='images/customized-services.png' alt='' onClick={() => handleServices('customized')}></img> */}
          <BsCartCheck className='icon' size={'50px'}/>
          <p>E-commerce</p>
        </div>
      </div>
    </div>
    <Footer />
    </>
  )
}
export default Services;