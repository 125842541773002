import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {

  const onClickPage = () => {
    window.scroll({
      top: 0,
      left: 0
    });
  }
  return (
    <div className='footer-container'>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
        <div className='footer-link-items'>
            <h2>Services</h2>
            <Link onClick={onClickPage} to='/services'>Customs Clearance</Link>
            <Link onClick={onClickPage} to='/services'>Freight Forwarding</Link>
            <Link onClick={onClickPage} to='/services'>Transport Solutions</Link>
            <Link onClick={onClickPage} to='/services'>Warehousing</Link>
            <Link onClick={onClickPage} to='/services'>Customized Operations</Link>
            <Link onClick={onClickPage} to='/services'>E-commerce</Link>
          </div>
          <div className='footer-link-items'>
            <h2>About Us</h2>
            <Link onClick={onClickPage} to='/about-us'>Our Mission & Vission</Link>
            <Link onClick={onClickPage} to='/about-us'>Our Core Values</Link>
            <Link onClick={onClickPage} to='/careers'>Careers</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Contact Us</h2>
            <Link onClick={onClickPage} to='/contact-us'>Contact us</Link>
            <Link onClick={onClickPage} to='https://www.facebook.com/l98brokerage'>Facebook</Link>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          {/* <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              <span style={{color:'red'}}>L</span>98
              <img src='/images/L98-Logo.jpg' alt='/' />
            </Link>
          </div> */}
          <small class='website-rights'>L98 Brokerage & Logistics Inc. Registered Copyright 2024</small>
          {/* <div className='social-icons'>
            <Link
              className='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i className='fab fa-facebook-f' />
            </Link>
            
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default Footer;
