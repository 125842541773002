import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Our Services</h1>
      <hr></hr>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/customs.png'
              text='Import & Export Releasing'
              label='Brokerage Services'
              path='/services'
            />
            <CardItem
              src='/images/cargo.png'
              text='Air and Sea Freight'
              label='Cargo Forwarding Services'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='/images/trucking-services.png'
              text='Trucking and Ground Transport'
              label='Transport Services'
              path='/services'
            />
            <CardItem
              src='images/warehouse.png'
              text='Warehouse Management'
              label='Warehousing Services'
              path='/services'
            />
            <CardItem
              src='images/customized-ops.png'
              text='Crafting, Packing & Vanning'
              label='Customized Operation Services'
              path='/services'
            />
             <CardItem
              src='images/warehouse.png'
              text='Fulfillment & Logistics'
              label='E-commerce'
              path='/services'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
