import React from 'react';
import '../../components/ContactUs.css';
import Footer from '../Footer';

function ContactUs() {

  return (
    <>
    <div className="contactUs-container">
     <div className="contactUs-img-container">
      <div className="contactUs-content">
        <p><span style={{color:'red'}}>C</span>ontact Us</p>
      </div>
    </div>
    <div className="contactUs-box"><span style={{color:'red'}}>M</span>anila
      <p>Contact Number: +63 8691-9469</p>
      <p>Email Address: L98admin@L98.com.ph</p>
      <p>Address: #8 Olivarez Plaza, NAIA Avenue, Brgy. San Dionisio, Paranaque City, Philippines</p>
      <a href="https://www.google.com/maps/place/L98+Brokerage+and+Logistics/@14.4942783,120.9903452,17z/data=!3m1!4b1!4m6!3m5!1s0x3397cf001712893f:0xff15174b6b17380f!8m2!3d14.4942731!4d120.9929201!16s%2Fg%2F11vt8z1t_v?entry=ttu">
        See on map
      </a>
    </div>
    <div className="contactUs-box"><span style={{color:'red'}}>C</span>ebu
      <p>Contact Number: +63 (32) 234-4869</p>
      <p>Email Address: L98admin@L98.com.ph</p>
      <p>Address: Zone 5J Bridges Townsquare Plaridel St. Alang-Alang Mandaue City, Cebu, Philippines</p>
      <a href="https://www.google.com/maps/place/L98+Brokerage+and+Logistics+Inc./@10.3357727,123.9310725,15z/data=!4m10!1m2!2m1!1sL98+Brokerage+and+Logistics+Cebu!3m6!1s0x33a999276d63ca63:0x661b07ca9ec1576!8m2!3d10.3357727!4d123.9501269!15sCiBMOTggQnJva2VyYWdlIGFuZCBMb2dpc3RpY3MgQ2VidZIBEWxvZ2lzdGljc19zZXJ2aWNl4AEA!16s%2Fg%2F11fj765p3z?entry=ttu">
        See on map</a>
    </div>
    <div className="contactUs-box"><span style={{color:'red'}}>D</span>avao
      <p>Contact Number: +63 (32) 234-4869</p>
      <p>Email Address: L98admin@L98.com.ph</p>
      <p>Address: Aminaway Business Park Km.12 Diversion Road, Sasa Davao City</p>
      <a href="https://www.google.com/maps/place/Ultradynamic+Fitness+Gym+Airport+Davao/@7.132052,125.6420085,3a,75y,239.95h,74.97t/data=!3m6!1e1!3m4!1szWPewY9BuYzz2CKWGuKESw!2e0!7i16384!8i8192!4m22!1m15!4m14!1m6!1m2!1s0x32f96bd20659366d:0x1e87f39d715c3490!2sAMINA+WAY+BLDG.,+4JQX%2BJXH,+Buhangin,+Davao+City,+Davao+del+Sur!2m2!1d125.6499977!2d7.1390708!1m6!1m2!1s0x32f96bfa0202b673:0xab153493b7e7eae7!2s12+Davao+City+Diversion+Rd,+Bunawan,+Davao+City,+Davao+del+Sur!2m2!1d125.6426993!2d7.132609!3m5!1s0x32f96b48fd9e1b69:0x6da0f3dfa8d5b147!8m2!3d7.1320848!4d125.6416473!16s%2Fg%2F11nx20l20j?entry=ttu">
        See on map
      </a>
    </div>
    
  </div>
  <Footer />
  </>
  )
}
export default ContactUs;