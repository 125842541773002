import React from 'react';
import CoreValueCard from './CoreValueCard';
import './CoreValueCard.css'; // Import CSS file for styling
const CoreValues = () => {
  const coreValues = [
    { logo: 'BsAwardFill', title: 'DISCIPLINE', items: ['Must value punctuality on working', 'Time and Deliverables', 'Must practice proper grooming', 'Grace under pressure'] },
    { logo: 'BsFillLightbulbFill', title: 'EFFICIENCY', items: ['Exercise anticipation for possible scenarios', 'Find immediate solutions'] },
    { logo: 'BsFillPersonCheckFill', title: 'INTEGRITY', items: ['Show respect to the persons in authority with appropriate conversation', 'Follow and enforce company policies', 'Be honest all the time'] },
    { logo: 'BsPeopleFill', title: 'TEAMWORK', items: ['Be united with the teammates as aligned with management\'s directive', 'Coach one another'] },
    { logo: 'BsFillBalloonHeartFill', title: 'LOYALTY', items: ['Dedication to the company will remain through ups and down', 'Never say bad things about the company', 'Observe confidentiality of records'] },
    { logo: 'BsChatHeartFill', title: 'CARE', items: ['Care for the company\'s assets', 'Concern for the company\'s revenue'] },
  ];

  return (
    <div className="core-values-container">
      {coreValues.map((coreValue, index) => (
        <CoreValueCard key={index} title={coreValue.title} items={coreValue.items} icon={coreValue.logo} />
      ))}
    </div>
  );
};

export default CoreValues;