import React from 'react';
import CareerCard from './CareerCard';
import './CoreValueCard.css'; // Import CSS file for styling
const CareerCardList = ({ careers }) => {
    return (
      <div className="career-card-list">
        {careers.map((career, index) => (
          <CareerCard
            key={index}
            title={career.title}
            location={career.location}
            description={career.description}
            requirements={career.requirements}
          />
        ))}
      </div>
    );
  };
  
  export default CareerCardList;